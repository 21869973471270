<template>
  <div class="relative">
    <button @click="opened = !opened" class="flex border border-gray-300 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen rounded-md px-3 py-2">
      <span class="text-sm mr-2 whitespace-nowrap">{{ filtered ? `etap ${step}${date_from ? `, od: ${date_from}` : ''}, do: ${date_to}` : 'filtruj' }}</span>
      <FunnelIcon class="h-5 w-5 text-gray-400 " />
    </button>
    <div v-if="opened" class="mt-1 bg-white p-2 absolute rounded-md shadow-sm right-0 top-full ring-1 ring-black ring-opacity-5">
      <div class="flex flex-col mt-1">
        <label class="text-xs">etap:</label>
        <select v-model="step" class="text-sm border border-gray-300 rounded-md focus:ring-ifgreen focus:border-ifgreen">
          <option v-for="index in 10" :key="index" :value="index">{{ index }}</option>
        </select>
      </div>
      <div class="flex flex-col mt-1">
        <label class="text-xs">data od:</label>
        <input v-model="date_from" class="text-sm border border-gray-300 rounded-md focus:ring-ifgreen focus:border-ifgreen" type="date" />
      </div>
      <div class="flex flex-col mt-1">
        <label class="text-xs">data do:</label>
        <input v-model="date_to" class="text-sm border border-gray-300 rounded-md focus:ring-ifgreen focus:border-ifgreen" type="date" />
      </div>
      <div class="mt-4">
        <span class="relative z-0 w-full inline-flex shadow-sm rounded-md">
          <button @click="cancel" type="button" class="relative inline-flex items-center justify-center px-2 py-2 w-1/2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <span class="sr-only">Previous</span>
            <XMarkIcon class="h-5 w-5 mr-2" aria-hidden="true" />
            Wyczyść
          </button>
          <button @click="apply" type="button" class="-ml-px relative inline-flex items-center justify-center px-2 py-2 w-1/2 rounded-r-md border border-gray-300 bg-ifgreen text-sm font-medium text-white hover:bg-ifgreen-dark focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
            <span class="sr-only">Next</span>
            Zastosuj
            <CheckIcon class="h-5 w-5 ml-2" aria-hidden="true" />
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XMarkIcon, FunnelIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    CheckIcon,
    XMarkIcon,
    FunnelIcon
  },
  emits: ['filter'],
  data() {
    return {
      opened: false,
      step: 1,
      date_from: null,
      date_to: new Date().toLocaleString('sv').slice(0,10),
      filtered: false,
    }
  },
  methods: {
    apply () {
      this.opened = false
      this.filtered = true
      const d2 = new Date(this.date_to)
      d2.setDate(d2.getDate() + 1);
      this.$emit('filter', {'deviceExtension.deviceLogs.movedToStep': this.step, 'deviceExtension.deviceLogs.timestamp[after]': this.date_from, 'deviceExtension.deviceLogs.timestamp[before]': d2.toLocaleString('sv').slice(0,10)})
    },
    cancel () {
      this.opened = false
      if (this.filtered) {
        this.filtered = false
        this.step = 1
        this.date_from = null
        const d2 = new Date()
        this.date_to = d2.toLocaleString('sv').slice(0,10)
        d2.setDate(d2.getDate() + 1);
        this.$emit('filter', {'deviceExtension.deviceLogs.movedToStep': this.step, 'deviceExtension.deviceLogs.timestamp[after]': this.date_from, 'deviceExtension.deviceLogs.timestamp[before]': d2.toLocaleString('sv').slice(0,10)})
      }
    },
    closeOnClickOutside: function(event){
      if (!this.$el.contains(event.target)){
        this.opened = false
      }
    }
  },
  created: function() {
    window.addEventListener('click', this.closeOnClickOutside, false);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeOnClickOutside, false);
  }
}
</script>
