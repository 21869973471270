<template>
  <li class="col-span-1 bg-white border border-gray-200 rounded-lg shadow divide-y divide-gray-200 flex flex-col justify-between">
    <div class="w-full flex items-center justify-between px-6 py-4 space-x-6">
      <div class="flex-1 truncate">
        <div class="flex items-start justify-between">
          <div>
            <div class="pr-8 relative">
              <p class="text-gray-900 text-sm font-medium">{{ startDate }}</p>
              <span class="text-xs text-gray-500 block lowercase">{{$t("service.started")}}</span>
            </div>
          </div>
          <span v-if="service.status === 'done'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {{$t("service.done")}}
          </span>
          <span v-if="service.status === 'rejected'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            {{$t("service.rejected")}}
          </span>
          <span v-else-if="service.status === 'require_spare_parts'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.requireParts")}}
          </span>
          <span v-else-if="service.status === 'spare_parts_sent'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.sentParts")}}
          </span>
        </div>
        <div v-if="service.status === 'done'" class="flex items-start justify-between mt-3">
          <div>
            <div class="pr-8 relative">
              <p class="text-gray-900 text-sm font-medium">{{ endDate }}</p>
              <span class="text-xs text-gray-500 block">{{$t("service.done")}}</span>
            </div>
          </div>
        </div>
        <div v-if="!shortened" class="mt-3">
          <p class="text-gray-900 text-sm font-medium truncate">{{ installation.customerName }}</p>
          <p class="text-gray-500 text-sm truncate">{{ installation.customerAddress }}, {{ installation.customerCity }} {{ installation.customerZipcode }}</p>
        </div>
        <div v-if="service.rejectionReason" class="mt-3">
          <p class="text-gray-900 text-sm font-medium truncate">{{$t("service.rejectionReason")}}</p>
          <p class="text-gray-500 text-sm truncate">{{ service.rejectionReason }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="-mt-px flex flex-wrap divide-x divide-gray-200" :class="done && 'divide-y'">
        <div v-if="service.status === 'require_spare_parts' && !hideControls" class="w-1/3 flex opacity-50">
          <button @click="notify" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ClockIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("wait")}}</span>
          </button>
        </div>
        <div v-if="service.status !== 'done' && hideControls" class="w-full flex">
          <router-link to="/admin/services" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ArrowRightIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("seeDetails")}}</span>
          </router-link>
        </div>
        <div v-if="service.status === 'spare_parts_sent' && !hideControls" class="w-1/3 flex" :class="service.status === 'require_spare_parts' && 'opacity-50'">
          <router-link :to="`/services/${service.id}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ArrowRightIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.finish")}}</span>
          </router-link>
        </div>
        <div v-if="done && service.serviceCard" class="w-full flex">
          <a :href="service.serviceCard" target="_blank" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ArrowDownTrayIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.serviceCard")}}</span>
          </a>
        </div>
        <div v-if="done" class="w-full flex divide-x divide-gray-200">
          <div v-for="link in images.links" :key="link.label" class="flex" :class="images.className">
            <a :href="link.src" target="_blank" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <PhotoIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
              <span class="ml-2">{{ link.label }}</span>
            </a>
          </div>
        </div>
        <div v-if="!done && !hideControls" class="-ml-px w-1/3 flex">
          <a :href="`tel:${installation.customerPhone}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <PhoneIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.customer")}}</span>
          </a>
        </div>
        <div v-if="!done && !hideControls" class="-ml-px w-1/3 flex">
          <a :href="`tel:${installation.installer.organisation.supportPhoneNumber}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <PhoneIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{ installation.installer.organisation.name }}</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { BellIcon, ArrowDownTrayIcon, PhotoIcon, PhoneIcon, ArrowRightIcon, ClockIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    PhoneIcon,
    ClockIcon,
    ArrowRightIcon,
    ArrowDownTrayIcon,
    PhotoIcon,
    BellIcon
  },
  emits: [''],
  props: {
    service: Object,
    shortened: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startDate () {
      return new Date(this.service.serviceStartDate).toLocaleDateString('pl-PL')
    },
    endDate () {
      return new Date(this.service.serviceEndDate).toLocaleDateString('pl-PL')
    },
    installation () {
      const serviceDevice = this.service.customDevice
      return serviceDevice.installation
    },
    images () {
      const { issue1Image, issue2Image, fixed1Image, fixed2Image } = this.service
      const links = [{src: issue1Image, label: `${this.$t('service.issue')} 1`}]
      if (issue2Image) links.push({src: issue2Image, label: `${this.$t('service.issue')} 2`})
      if (fixed1Image) links.push({src: fixed1Image, label: `${this.$t('service.fixed')} 1`})
      if (fixed2Image) links.push({src: fixed2Image, label: `${this.$t('service.fixed')} 2`})
      const className = links.length > 1 ? `w-1/${links.length}` : 'w-full'
      return {links, className}
    },
    done () {
      return this.service.status === 'done'
    }
  },
  methods: {
    notify () {
      this.$notify({
        title: `${this.$t('template.warning')}`,
        text: `${this.$t('service.completeServiceText1')} ${process.env.VUE_APP_ORG_NAME} ${this.$t('service.completeServiceText2')}`,
        type: 'warning',
      })
    },
  }
}
</script>
