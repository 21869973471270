<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton @click="loadData" title="Historia" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
        <span class="sr-only">Open options</span>
        <ArchiveBoxIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="w-80 z-10 origin-top-right absolute right-0 mt-2 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div v-if="logs">
          <div class="flex bg-gray-50">
            <p class="w-1/6 px-2 py-2 text-left text-xs text-gray-500">
              etap
            </p>
            <p class="w-2/6 px-2 py-2 text-left text-xs text-gray-500">
              wykonany przez
            </p>
            <p class="w-1/2 px-2 py-2 text-right text-xs text-gray-500">
              data rozpoczęcia
            </p>
          </div>
          <div v-for="(log, index) in logs" :key="index" class="flex" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <p class="w-1/6 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ log.movedToStep }}</p>
            <p class="w-2/6 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ log.endedBy }}</p>
            <p class="w-1/2 px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-right">{{ new Date(log.timestamp).toLocaleString('pl-PL') }}</p>
          </div>
        </div>
        <div v-else class="flex align-center justify-center">
          <CogIcon class="h-12 w-12 text-ifgreen animate-spin" aria-hidden="true" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { ArchiveBoxIcon, CogIcon } from '@heroicons/vue/24/outline'
import { api } from "@/assets/js/api-client.js";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    ArchiveBoxIcon,
    CogIcon
  },
  props: {
    extensionId: Number
  },
  data () {
    return {
      logs: null
    }
  },
  methods: {
    loadData () {
      if (!this.logs) {
        api.get(`/device_extensions/${this.extensionId}/device_logs?order[timestamp]`).then(result => {
          this.logs = result.data
        })
      }
    }
  }
}
</script>
