<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton title="Uwagi" :class="device.deviceExtension.note ? 'pulse' : ''"  class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
        <span class="sr-only">Open note</span>
        <PencilSquareIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-80 p-3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <label for="note" class="block text-sm font-medium text-gray-700">
          Uwagi
        </label>
        <div class="mt-1 relative">
          <textarea
            id="note"
            name="note"
            @keydown.stop
            :value="device.deviceExtension.note"
            @change="updateNote"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    PencilSquareIcon,
  },
  emits: ['update-note'],
  props: {
    device: Object
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    updateNote (e) {
      this.$emit('update-note', {value: e.target.value, id: this.device.id})
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(153, 190, 58, 0.6);
  }
  70% {
      box-shadow: 0 0 0 20px rgba(153, 190, 58, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(153, 190, 58, 0);
  }
}

.pulse{
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
