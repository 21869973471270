<template>
  <div>
    <div class="block text-sm text-gray-700 my-4">
      {{ descriptionTranslations?.[$i18n.locale] ? descriptionTranslations[$i18n.locale] : description }}
    </div>
    <ul role="list" class="space-y-2">
      <li v-for="(item, index) in filteredFields" :key="item.name">
        <div v-show="item.type == 'string'">
          <BarcodeScanner
            v-if="item.barcodeScanner"
            v-model="value[item.name]"
            :label="item.labels?.[$i18n.locale] ? item.labels?.[$i18n.locale] : item.label"
            :placeholder="item.labels?.[$i18n.locale] ? item.labels?.[$i18n.locale] : item.label"
            :compact="true"
          />
          <InputWithErrorVue
            v-else
            v-model="value[item.name]"
            type="text"
            :label="item.labels?.[$i18n.locale] ? item.labels?.[$i18n.locale] : item.label"
            :placeholder="item.labels?.[$i18n.locale] ? item.labels?.[$i18n.locale] : item.label"
          />
        </div>
        <div v-show="item.type == 'list'">
          <NativeSelect
            :id="'itemList-' + index"
            :label="item.labels?.[$i18n.locale] ? item.labels?.[$i18n.locale] : item.label"
            :options="listItems(item)"
            v-model="value[item.name]"
          />
        </div>
      </li>
    </ul>
    <div v-if="button && value" :class="preview ? 'py-6' : 'fixed left-0 right-0 bottom-0 px-4 border-t border-gray-200 py-4'" class="bg-white">
      <button
        :class="!value.validated ? 'opacity-60' : ''"
        class="flex w-full justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen-dark"
        @click="submit"
      >
        {{ $t('add') }}
      </button>
    </div>
  </div>
</template>
<script>
import InputWithErrorVue from "@/components/inputs/InputWithError.vue";
import NativeSelect from "@/components/inputs/NativeSelect.vue";
import BarcodeScanner from "@/components/inputs/BarcodeScanner.vue";
import { QrCodeIcon } from "@heroicons/vue/24/solid";
export default {
  components: {
    InputWithErrorVue,
    BarcodeScanner,
    NativeSelect,
    QrCodeIcon,
  },
  emits: ['update:modelValue', 'submit', 'invalid'],
  props: {
    modelValue: Object,
    preview: {
      type: Boolean,
      default: true
    },
    button: {
      type: Boolean,
      default: true
    },
    description: String,
    descriptionTranslations: Object,
    schema: Array,
  },
  data () {
    return {
      value: {
        validated: false
      }
    }
  },
  computed: {
    filteredFields () {
      return this.schema.filter(field => {
        if (!field.conditionalLogic || !field.conditionalLogic[0].length ) return true
        else {
          let orSatisfied = false
          field.conditionalLogic.forEach(or => {
            let andSatisfied = true
            or.forEach(and => {
              if (!and.field) return true
              const type = this.schema.find(field => {return field.name == and.field}).type
              if (type == 'string') {
                const conditionValue = and.value[0] === "" ? null : and.value[0]
                const fieldValue = this.value[and.field] === "" ? null : this.value[and.field]
                andSatisfied *= and.operator == 'in' ? fieldValue == conditionValue : fieldValue != conditionValue
              } else {
                andSatisfied *= and.operator == 'in' ? and.value.includes(this.value[and.field]) : !and.value.includes(this.value[and.field])
              }
            })
            orSatisfied += andSatisfied
          })
          return orSatisfied
        }
      })
    }
  },
  methods: {
    listItems (item) {
      const temparray = [];
      if (item?.choices) {
        item.choices.map((e) =>
          temparray.push({ 
            value: e.values?.[this.$i18n.locale] ? e.values?.[this.$i18n.locale] : e.value, 
            name: e.values?.[this.$i18n.locale] ? e.values?.[this.$i18n.locale] : e.value 
          })
        );
      }
      return temparray;
    },
    submit () {
      if (this.value.validated) this.$emit('submit')
      else this.$emit('invalid')
    },
    validate () {
      this.value.validated = this.filteredFields.every((field) => {
        return this.value[field.name]
      })
    },
    cleanUpValue () {
      Object.keys(this.value).forEach(key => {
        if (key != 'validated' && !this.filteredFields.find(field => {return field.name == key})) delete this.value[key]
      })
    }
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler () {
        this.validate()
        if (this.modelValue) this.value = this.modelValue
      }
    },
    value: {
      deep: true,
      handler () {
        this.cleanUpValue()
        this.validate()
        this.$emit('update:modelValue', this.value)
      }
    },
  }

};
</script>
