<template>
  <div class="border-b border-gray-200 sticky top-16 z-10 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="-mb-px flex justify-between">
        <div class="flex items-center">
          <div class="flex space-x-4 md:space-x-8" aria-label="Tabs">
            <a v-for="tab in tabs" @click="currentTab = tab.name" :key="tab.name" href="#" :class="[tab.name === currentTab ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm']">
              {{ tab.label }}
              <span :class="[tab.name === currentTab ? 'bg-ifgreen text-white' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block']">{{ tab.count }}</span>
            </a>
          </div>
        </div>
        <div class="py-2">
          <button @click="openFilters" type="button" class="hidden md:flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{ $t('search') }}</span>
          </button>
        </div> 
      </nav>
    </div>
  </div>
  <Filters ref="filtersPanel" entity="services" :showServicesFields="true" />
  <div 
    v-for="tab in tabs"
    v-show="currentTab === tab.name"
    :key="tab.name"
  >
    <AdminPagination
      entity="services"
      :storeKey="tab.name"
      :status="tab.status"
    >
      <AdminServiceCard
        v-for="ser in services[tab.name].data"
        :key="ser.id"
        :service="ser"
        @resolve-service="openConfirmModal"
      />
    </AdminPagination>
  </div>
  <Modal
    ref="modal"
    :title="modalTitle"
    :text="modalText"
    :cancel-button-label="$t('cancel')"
  >
    <template v-if="modalAction === 'rejected'" #textarea>
      <div class="w-full mt-5">
        <label for="rejectionReason" class="block text-sm font-medium text-gray-700">
          {{$t("service.rejectionReason")}}
        </label>
        <div class="mt-1 relative">
          <textarea
            id="rejectionReason"
            name="rejectionReason"
            v-model="rejectionReason"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
    </template>
    <button v-if="modalAction === 'send_spare_parts'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="resolveService('spare_parts_sent')">
      {{$t("notify")}}
    </button>
    <button v-else-if="modalAction === 'done'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="resolveService('done')">
      {{$t("finish")}}
    </button>
    <button v-else-if="modalAction === 'rejected'" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm" @click="resolveService('rejected')">
      {{$t("reject")}}
    </button>
  </Modal>
</template>

<script>
import AdminServiceCard from '@/components/admin/AdminServiceCard.vue'
import Modal from '@/components/layout/Modal.vue';
import AdminPagination from '@/components/admin/AdminPagination.vue'
import Filters from '@/components/layout/Filters.vue'
import { CogIcon, CheckIcon, ExclamationTriangleIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { getDeviceSerialNumber } from '@/assets/js/helpers.js'
import { mapState } from 'vuex'
import { api, patchHeaders } from "@/assets/js/api-client.js";

export default {
  name: "AdminServices",
  components: {
    AdminServiceCard,
    AdminPagination,
    Filters,
    Modal,
    CogIcon,
    CheckIcon,
    ExclamationTriangleIcon,
    MagnifyingGlassIcon
  },
  data () {
    return {
      currentTab: 'pending',
      modalTitle: null,
      modalText: null,
      modalAction: null,
      actionService: null,
      rejectionReason: null
    }
  },
  computed: {
    ...mapState(['organisation', 'role', 'services']),
    tabs () {
      return [
        { label: `${this.$t('pending')}`, name: 'pending', status: "require_spare_parts,spare_parts_sent", count: this.services.pending.pagination.total },
        { label: `${this.$t('accepted')}`, name: 'done', status: "done", count: this.services.done.pagination.total },
        { label: `${this.$t('rejected')}`, name: 'rejected', status: "rejected", count: this.services.rejected.pagination.total }
      ]
    },
  },
  methods: {
    openFilters () {
      this.$refs.filtersPanel.show()
    },
    openConfirmModal(e) {
      this.modalTitle = e.title
      this.modalText = e.text
      this.modalAction = e.action
      this.actionService = e.service
      this.$refs.modal.show()
    },
    resolveService (status) {
      this.$refs.modal.close()
      setTimeout(() => {
        this.$store.dispatch('setLoading', true)
      }, 10)

      const data = {
        id: this.actionService.id,
        status
      }

      if (status === 'rejected') data['rejectionReason'] = this.rejectionReason

      if (status === 'spare_parts_sent') data['sparePartsSentDate'] = new Date().toLocaleString('sv').slice(0,10)
      else data['serviceEndDate'] = new Date().toLocaleString('sv').slice(0,10)

      api.patch(`/services/${this.actionService.id}`, data, patchHeaders).then(result => {
        console.log(result)
        
        this.tabs.forEach(tab => {
          if (tab.status.includes(status)) {
            this.$store.dispatch('getCollection', {entity: 'services', key: tab.name, status: tab.status})
          }
        })

        if (status === 'rejected') {

          const serviceDevice = this.actionService.customDevice

          const emails = [ this.actionService.installer.email ]
          if (serviceDevice.installation.receivers) serviceDevice.installation.receivers.forEach(receiver => {emails.push(receiver.email)})
          if (serviceDevice.installation.customerEmail) emails.push(serviceDevice.installation.customerEmail)

          const emailData = {
            organisation: this.organisation,
            device_id: getDeviceSerialNumber(serviceDevice),
            execution_date: new Date(this.actionService.serviceStartDate).toLocaleDateString('pl-PL'),
            email: emails
          }

          if (this.rejectionReason) emailData['note'] = this.rejectionReason

          this.sendRejection(emailData)

        } else {
          this.$store.dispatch('setLoading', false)
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    sendRejection (data) {
      api.post('/send-email', {action: 'rejectService', data}).then(result => {
        console.log(result)
        this.$store.dispatch('setLoading', false)
        this.$notify({
          title: data.email,
          text: `${this.$t("service.sendRejectionText")}`,
          type: 'success',
          duration: 5000
        })
      }).catch((error) => {
        console.log(error)
        this.$store.dispatch('setLoading', false)
      })
    }
  },
  watch: {
    async organisation () {
      this.$store.dispatch('setLoading', true)
      await this.$store.dispatch('getCollection', {entity: 'services', key: 'pending', status: "require_spare_parts,spare_parts_sent", page: 1})
      await this.$store.dispatch('getCollection', {entity: 'services', key: 'done', status: "done", page: 1})
      await this.$store.dispatch('getCollection', {entity: 'services', key: 'rejected', status: "rejected", page: 1})
      this.$store.dispatch('setLoading', false)
    }
  }
};
</script>
