<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton :title="links ? 'Zdjęcia' : 'Parametry'" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
        <span class="sr-only">Open options</span>
        <PhotoIcon v-if="links" class="h-5 w-5" aria-hidden="true" />
        <ClipboardDocumentListIcon v-if="params" class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-66 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div v-if="links">
          <div v-if="links.length" class="py-1">
            <MenuItem v-for="(link, index) in links" v-slot="{ active }" :key="index">
              <a :href="link.url" target="_blank" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ link.name }}</a>
            </MenuItem>
          </div>
          <div v-else>
            <p class="text-gray-700 block px-4 py-2 text-sm">Nie dodano jeszcze żadnych zdjęć</p>
          </div>
        </div>
        <div v-if="params">
          <div v-if="params.length" class="py-1 divide-y divide-gray-200 ">
            <MenuItem v-for="(param, index) in params" :key="index">
              <div class="flex justify-between">
                <p class="text-sm px-4 py-2 text-sm text-gray-700">{{ param.label }}: </p>
                <p class="text-sm px-4 py-2 text-sm text-gray-700 font-medium">{{ param.value }}</p>
              </div>
            </MenuItem>
          </div>
          <div v-else>
            <p class="text-gray-700 block px-4 py-2 text-sm">Nie zdefiniowano jeszcze parametrów</p>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { PhotoIcon, ClipboardDocumentListIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    PhotoIcon,
    ClipboardDocumentListIcon
  },
  props: {
    links: Array,
    params: Array
  }
}
</script>
