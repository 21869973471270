<template>
  <li class="col-span-1 bg-white border border-gray-200 rounded-lg shadow divide-y divide-gray-200 flex flex-col justify-between">
    <div class="w-full flex items-center justify-between px-6 py-4 space-x-6">
      <div class="flex-1 w-full">
        <div class="flex items-start justify-between">
          <div>
            <div class="pr-8 relative">
              <p class="text-gray-900 text-sm font-medium">{{ scheduledDate }}</p>
              <span class="text-xs text-gray-500 block">{{$t("service.deadline")}}</span>
              <BellIcon v-if="scheduledIncoming" class="w-5 h-5 absolute top-0 -right-1 animate-bounce" />
            </div>
            <div v-if="appointmentDate && inspection.status !== 'done'" class="mt-2 pr-8 relative">
              <p class="text-gray-900 text-sm font-medium">{{ appointmentDate }}</p>
              <span class="text-xs text-gray-500 block">{{$t("service.scheduled")}}</span>
              <BellIcon v-if="appointedIncoming" class="p-0.5 w-6 h-6 bg-yellow-500 text-white rounded-full absolute top-1 -right-1" />
            </div>
          </div>
          <span v-if="inspection.status === 'done' && doneInTime" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            {{$t("service.executedInTime")}}
          </span>
          <span v-else-if="inspection.status === 'done' && !doneInTime"  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.executedNotInTime")}}
          </span>
          <span v-else-if="overdue" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            {{$t("service.overdue")}}
          </span>
          <span v-else-if="inspection.requiredForWarranty" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            {{$t("service.warranty")}}
          </span>
        </div>
        <div v-if="inspection.status === 'done'" class="flex items-start justify-between mt-3">
          <div>
            <div class="pr-8 relative">
              <p class="text-gray-900 text-sm font-medium">{{ executionDate }}</p>
              <span class="text-xs text-gray-500 block">{{$t("service.done")}}</span>
            </div>
          </div>
        </div>
        <div v-if="!shortened" class="mt-3">
          <p class="text-gray-900 text-sm font-medium truncate">{{ installation.customerName }}</p>
          <p class="text-gray-500 text-sm truncate">{{ installation.customerAddress }}, {{ installation.customerCity }} {{ installation.customerZipcode }}</p>
        </div>
      </div>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div v-if="inspection.status === 'pending' && !hideControls" class="w-1/3 flex">
          <router-link :to="`/inspections/${inspection.id}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ArrowRightIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.execute")}}</span>
          </router-link>
        </div>
        <div v-if="inspection.status === 'pending' && !hideControls" class="w-1/3  flex">
          <button @click="scheduleInspection" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none">
            <ClockIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.schedule")}}</span>
          </button>
        </div>
        <div v-if="inspection.status === 'done'" class="w-full flex">
          <a :href="inspectionCardLink" target="_blank" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <ArrowDownTrayIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("inspection.inspectionCard")}}</span>
          </a>
        </div>
        <div v-if="inspection.status === 'pending' && !hideControls" class="-ml-px w-1/3 flex">
          <a :href="`tel:${installation.customerPhone}`" class="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <PhoneIcon class="w-4 h-4 text-gray-400" aria-hidden="true" />
            <span class="ml-2">{{$t("service.call")}}</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>

import { BellIcon, ArrowDownTrayIcon, PhoneIcon, ArrowRightIcon, ClockIcon } from '@heroicons/vue/24/outline'
import { s3url } from '@/assets/js/helpers.js'

export default {
  components: {
    PhoneIcon,
    ClockIcon,
    ArrowRightIcon,
    ArrowDownTrayIcon,
    BellIcon,
  },
  emits: ['schedule-inspection'],
  props: {
    inspection: Object,
    shortened: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    installation () {
      const inspectionDevice = this.inspection.customDevice
      return inspectionDevice.installation
    },
    doneInTime () {
      return new Date(this.inspection.scheduledDate).getTime() > new Date(this.inspection.executionDate).getTime()
    },
    overdue () {
      return new Date(this.inspection.scheduledDate).getTime() < new Date().getTime()
    },
    scheduledDate () {
      return new Date(this.inspection.scheduledDate).toLocaleDateString('pl-PL')
    },
    scheduledIncoming () {
      const notDone = this.inspection.status !== 'done'
      const today = new Date()
      const limit = new Date(today.getTime() + (30 * 24 * 60 * 60000))
      const scheduled = new Date(this.inspection.scheduledDate)
      const incoming = scheduled.getTime() < limit.getTime()
      return notDone & incoming
    },
    appointmentDate () {
      return this.inspection.appointmentDate ? new Date(this.inspection.appointmentDate).toLocaleString('pl-PL') : null
    },
    executionDate () {
      return this.inspection.executionDate ? new Date(this.inspection.executionDate).toLocaleDateString('pl-PL') : null
    },
    appointedIncoming () {
      const notDone = this.inspection.status !== 'done'
      const today = new Date()
      const limit = new Date(today.getTime() + (30 * 24 * 60 * 60000))
      const appointed = new Date(this.inspection.appointmentDate)
      const incoming = (this.inspection.appointmentDate) && (appointed.getTime() < limit.getTime())
      return notDone & incoming
    },
    inspectionCardLink () {
      return this.inspection.inspectionCard ? this.inspection.inspectionCard : `${s3url}${this.inspection.customDevice.id}-${this.inspection.executionTime}-inspection-card.pdf`
    }
  },
  methods: {
    scheduleInspection () {
      this.$emit('schedule-inspection', this.inspection)
    }
  }
}
</script>
