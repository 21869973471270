<template>
  <div>
    <label v-if="label" :for="id" class="block mb-1 text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="relative">
      <input
        v-if="type != 'textarea'"
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
        :value="modelValue"
        :id="id"
        :name="name"
        :type="type"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :required="true"
        class="form-input"
        :class="validation && validation.$errors.length ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'placeholder-gray-400 border-gray-300'"
      />
      <textarea
        v-else
        @input="handleInput"
        @change="handleChange"
        @blur="handleBlur"
        :value="modelValue"
        :id="id"
        :name="name"
        :type="type"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :required="true"
        class="form-input"
        :class="validation && validation.$errors.length ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : 'placeholder-gray-400 border-gray-300'"
      />
      <div v-if="validation && validation.$errors.length" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </div>
    <div v-if="validation && validation.$errors">
      <p v-for="error in validation.$errors" :key="error.$uid" class="mt-2 text-sm text-red-600">{{ error.$message }}</p>
    </div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";

export default {
  components: {
    ExclamationCircleIcon
  },
  emits: ['update:modelValue','change'],
  props: {
    modelValue: String,
    label: String,
    placeholder: String,
    id: String,
    type: String,
    name: String,
    autocomplete: String,
    inputmode: String,
    errorText: String,
    validation: Object,
    lazy: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput (e) {
      if (!this.lazy) this.$emit('update:modelValue', e.target.value)
    },
    handleChange (e) {
      if (this.lazy) this.$emit('update:modelValue', e.target.value)
    },
    handleBlur () {
      this.$emit('change')
      if (this. validation) this.validation.$touch()
    }
  }
}


</script>

<style scoped lang="scss">
.form-input {
  @apply appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm;
}
</style>
