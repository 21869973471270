<template>
  <Listbox v-if="selected" as="div" v-model="selected">
    <div class="relative">
      <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen sm:text-sm">
        <span class="block truncate">{{ selected.name }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <BarsArrowDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="option in options" :key="option.id" :value="option" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-ifgreen' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ option.name }}
              </span>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-ifgreen', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, BarsArrowDownIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    BarsArrowDownIcon
  },
  props: {
    filteredByStep: Number
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    options () {

      // let stepSortingLabel = `data awansu na etap ${this.filteredByStep}`
      // if (this.filteredByStep === 1) stepSortingLabel = 'data dodania'
      // if (this.filteredByStep === 9) stepSortingLabel = 'data zak. produkcji'

      return [
        { id: 1, value: 'createdAt', name: 'data dodania' },
        { id: 2, value: 'modifiedAt', name: 'data modyfikacji' }
      ]
    }
  },
  watch: {
    options: {
      immediate: true,
      handler () {
        this.selected = this.options[0]
      }
    },
    selected () {
      this.$emit('select', this.selected.value)
    }
  }
}
</script>
