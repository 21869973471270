<template>
  <div class="mt-3 col-span-1 bg-white rounded-lg border border-gray-200 shadow divide-y divide-gray-200 overflow-hidden flex flex-col justify-between">
    <div v-if="loadingCondition" class="flex align-center justify-center py-10 px-6">
      <CogIcon class="h-12 w-12 text-ifgreen animate-spin" aria-hidden="true" />
    </div>
    <div v-else-if="displayCondition" class="flex align-center justify-center py-6 px-6">
      <p>
        {{ placeholder }}
      </p>
    </div>
  </div>
</template>

<script>
import { CogIcon } from '@heroicons/vue/24/outline'

export default {
  name: "AdminLoadingCard",
  components: {
    CogIcon
  },
  props: {
    loadingCondition: Boolean,
    displayCondition: Boolean,
    placeholder: String
  },
}
</script>
