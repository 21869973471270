<template>

        <div :class="classes" class="relative flex justify-between w-full">
          <NativeSelect 
            class="w-5/12" 
            :options="options" 
            v-model="search.param" 
            :icon="true" 
            :rounded-right="false"
            placeholder="Wszystko (wśród pobranych)" 
          />
          <div class="w-6/12 flex-shrink-0">
            <label for="search" class="sr-only">Wyszukaj</label>
            <div class="relative">
              <input v-model="search.value" @keyup.enter="handleSearch" id="search" name="search" class="block w-full bg-white border border-gray-300 py-2 px-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-ifgreen focus:border-ifgreen sm:text-sm" :placeholder="placeholder" type="search" />
            </div>
          </div>
          <button title="resetuj wyszukiwanie" @click="handleClear" class="border border-gray-300 hover:bg-gray-50 rounded-r-md w-1/12 flex justify-center items-center">
            <XMarkIcon class="text-gray-700 flex w-6 h-6" />
          </button>
        </div>


</template>
<script>
import { XMarkIcon } from '@heroicons/vue/24/solid'
import NativeSelect from '@/components/inputs/NativeSelect.vue' 

export default {
    props:{
        classes: String,
        modelValue: Object,
        placeholder: {
          type: String,
          default: 'Wyszukaj'
        },
        options: Array
    },
    emits: ['update:modelValue', 'search'],
    components: {
      XMarkIcon,
      NativeSelect
    },
    data () {
      return {
        search: {
          value: "",
          param: null
        }
      }
    },
    methods: {
      handleClear() {
        this.search.param = null
        this.search.value = ""
        this.$emit('search')
      },
      handleSearch () {
        if (this.search.param) this.$emit('search')
      }
    },
    watch: {
      search: {
        deep: true,
        handler() {
          this.$emit('update:modelValue', this.search)
        } 
      },
      modelValue: {
        immediate: true,
        deep: true,
        handler() {
          this.search = this.modelValue
        }
      }
    }
};

</script>
