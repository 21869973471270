<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton @click="loadData" title="Przeglądy" class="rounded-full flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
        <span class="sr-only">Open inspections</span>
        <ClockIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems style="width: 22rem;" class="z-10 origin-top-right absolute right-0 mt-2 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div v-if="inspections">
          <div class="flex bg-gray-50">
            <p class="w-1/4 px-2 py-2 text-left text-xs text-gray-500">
              {{$t('service.deadline')}}
            </p>
            <p class="w-1/4 px-2 py-2 text-left text-xs text-gray-500">
              {{$t("service.executed")}}
            </p>
            <p class="w-1/2 px-2 py-2 text-right text-xs text-gray-500 lowercase">
              {{$t("service.status")}}
            </p>
          </div>
          <div v-for="(insp, index) in inspections" :key="index" class="flex" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <p class="w-1/4 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ new Date(insp.scheduledDate).toLocaleDateString('pl-PL') }}</p>
            <p class="w-1/4 px-2 py-2 whitespace-nowrap text-sm text-gray-500">{{ insp.executionDate ? new Date(insp.executionDate).toLocaleDateString('pl-PL') : ''}}</p>
            <p class="w-1/2 px-2 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
              <span v-if="insp.status === 'done' && insp.doneInTime" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                {{$t("service.executedInTime")}}
              </span>
              <span v-else-if="insp.status === 'done' && !insp.doneInTime"  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                {{$t("service.executedNotInTime")}}
              </span>
              <span v-else-if="insp.overdue" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                {{$t("service.overdue")}}
              </span>
            </p>
          </div>
        </div>
        <div v-else class="flex align-center justify-center">
          <CogIcon class="h-12 w-12 text-ifgreen animate-spin" aria-hidden="true" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { ClockIcon, CogIcon } from '@heroicons/vue/24/outline'
import { api } from "@/assets/js/api-client.js";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    ClockIcon,
    CogIcon
  },
  props: {
    deviceId: String,
    customDevice: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inspections: null
    }
  },
  methods: {
    loadData () {
      if (!this.inspections) {
        const endpoint = this.customDevice ? 'custom_devices' : 'devices'
        api.get(`/${endpoint}/${this.deviceId}/inspections`).then(result => {
          console.log(result)
          this.inspections = result.data.map(insp => {
            if (insp.status === 'done') {
              const doneInTime = new Date(insp.scheduledDate).getTime() > new Date(insp.executionDate).getTime()
              return {...insp, doneInTime}
            }
            else {
              const overdue = new Date(insp.scheduledDate).getTime() < new Date().getTime()
              return {...insp, overdue}
            }
          })
        })
      }
    }
  }
}
</script>
